import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { Button } from '@vizsla/components';
import {
  useRegisterAttendeesWithOutPaymentMutation,
  TeamFundraising,
  useGetPartnershipMatchingCommitmentsQuery,
  useUpdatePartnershipCommitmentMutation,
} from '@vizsla/graphql';
import {
  useShoppingCartState,
  useNotification,
  useExperienceId,
  useIntegrationService,
  useDonorCommitment,
  createCartStore,
} from '@vizsla/hooks';
import {
  buildUrl,
  calculateShoppingCartTotal,
  generateDonationMatchCommitment,
  generatePartnershipMatchCommitment,
} from '@vizsla/utils';

import { useCurrentPublicCampaign } from 'src/hooks/campaign';
import { useSelectedExperience, useSelectedWaiver } from 'src/hooks';
import { useCurrentUser } from 'src/hooks/user';
import { useCheckoutWithPaymentMethod } from 'src/hooks/checkout';
import { LANDING_ROUTES } from 'src/constants';
import { useCurrentOrganization } from 'src/hooks/organization';

import { PaymentMethodCard } from './paymentMethodCard';
import { ContainerCards } from './paymentMethods.styles';
import { useBillingData, useIntegrationData } from '../CheckoutPage/CheckoutPageOnePage';
import { useTeamFundraisingDonate } from '../TeamFundraisingDonate/useTeamFundraisingDonate';
import { useTeamFundraisingDonate as useFundraisingDonate } from '../FundraiserDonation/useFundraiserDonate';
import { SavePaymentMethod } from './SavePaymentMethod';

const selectIntegrationResponse = state => ({
  items: state.items,
  addItem: state.add,
});

const selectTotalSectionState = state => {
  return calculateShoppingCartTotal(state.items);
};

export const AddPaymentMethodStep = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  refetch,
  loadingPaymentMethods,
  excuetePay,
  setExecutePay,
  setLoading,
  validatePaymentData,
  setValidatePaymentData,
  setCompletePayment,
}) => {
  const [otherMethod, setOtherMethod] = useState(false);
  const [existPaymentMethod, setExistPaymentMethod] = useState(false);
  const [executePayAfterCreditCartInit, setExecutePayAfterCreditCartInit] = useState(false);
  const [dataCreditCartInit, setDataCreditCartInit] = useState<any | null>(null);
  const notification = useNotification();
  const { user } = useCurrentUser();
  const { team } = useTeamFundraisingDonate();
  const { fundraiser } = useFundraisingDonate();
  const userId = useMemo(() => {
    return user?.id;
  }, [user]);
  const { organization } = useCurrentOrganization();
  const { campaign } = useCurrentPublicCampaign();
  const { data: experience } = useSelectedExperience();
  const { execute: checkout } = useCheckoutWithPaymentMethod();
  const waiverInfo = useSelectedWaiver(state => state.data);
  const selectItemsState = state => ({ items: state.items });
  const selectAddressState = state => ({ items: state.items });
  const { items } = useShoppingCartState(selectItemsState);
  const { addItem: addDonationMatchResponse } = useIntegrationData(selectIntegrationResponse);
  const formattedItems = items?.map((item, index) => {
    if (index === 0) {
      return {
        ...item,
        attendee: {
          ...item.attendee,
          zip:
            item?.attendee?.zip && typeof item?.attendee?.zip !== 'string'
              ? item.attendee.zip.toString()
              : item?.attendee?.zip,
          waiverSignature: waiverInfo?.waiverSignature ? waiverInfo?.waiverSignature : null,
        },
      };
    }
    if (index !== 0 && item.parent) {
      return {
        ...item,
        attendee: {
          ...item.attendee,
          zip:
            item?.attendee?.zip && typeof item?.attendee?.zip !== 'string'
              ? item.attendee.zip.toString()
              : item?.attendee?.zip,
          waiverSignature: waiverInfo?.waiverSignature ? waiverInfo?.waiverSignature : null,
        },
      };
    }
    return item;
  });
  const { items: billingInfo } = useBillingData(selectAddressState);
  const navigate = useNavigate();

  const total = useShoppingCartState(selectTotalSectionState);
  const [registerAttendeesWithOutPaymentMutation] = useRegisterAttendeesWithOutPaymentMutation();

  const donationData = formattedItems.find(item => item.type === 'donation');

  const { data: partnershipData } = useGetPartnershipMatchingCommitmentsQuery({
    variables: {
      campaignId: campaign?.id,
      experienceId: experience?.id ?? donationData?.experience?.id,
    },
  });

  const matchingCommitments =
    partnershipData?.getPartnershipMatchingCommitments?.matchingCommitments;

  const { createDonation: createDonationMatch, searchCompany: getCompanyDetails } =
    useIntegrationService({
      credentials: donationData?.integrationCredentials,
      integrationType: donationData?.integrationType,
    });

  const pathExperienceId = useExperienceId();

  const registerAttendees = async (experienceId, formattedItems, billingInfo, method) => {
    try {
      const response = await registerAttendeesWithOutPaymentMutation({
        variables: {
          data: {
            experience: { id: experienceId },
            shoppingCart: formattedItems,
            billingInfo: { ...(billingInfo?.[0] ?? {}), method },
          },
        },
      });
      return response.data?.registerAttendeesWithOutPayment;
    } catch (error) {
      console.error('Failed to register attendees:', error);
      throw new Error('Something went wrong while registering attendees.');
    }
  };

  const { createDonorCommitment } = useDonorCommitment(organization?.id as string);

  const [updatePartnershipCommitmentMutation] = useUpdatePartnershipCommitmentMutation({
    refetchQueries: ['ListPartnershipCommitments', 'PartnershipDashboardData'],
    awaitRefetchQueries: true,
  });

  const pay = async method => {
    const experienceId = experience?.id ?? pathExperienceId;
    if (experienceId) {
      try {
        const payment =
          total <= 0
            ? await registerAttendees(experienceId, formattedItems, billingInfo, method)
            : await checkout({
                data: {
                  experience: { id: experienceId },
                  userId: String(userId),
                  shoppingCart: formattedItems,
                  billingInfo: { ...(billingInfo?.[0] ?? {}), method },
                  paymentMethodId: method.id,
                },
              });

        if (payment?.success) {
          if (donationData?.integrationCompany) {
            const { companyDetails } = await getCompanyDetails(donationData?.integrationCompany);

            const { integrationResponse, donorCommitmentResponse, integrationDonationAmount } =
              await generateDonationMatchCommitment(
                {
                  integrationType: donationData?.integrationType,
                  integrationCompany: donationData?.integrationCompany,
                  userEmail: user?.email as string,
                  userFirstName: user?.firstName as string,
                  userLastName: user?.lastName as string,
                  amount: donationData?.price,
                  fundraiser,
                  team: fundraiser
                    ? (fundraiser.attendee?.teamMembership?.team as TeamFundraising) || null
                    : (team as TeamFundraising),
                  experienceId: experience?.id ?? donationData?.experience?.id,
                  campaignId: campaign?.id as string,
                  donorId: user?.id as string,
                  campaignName: campaign?.name as string,
                  transactionId: donationData.id,
                  companyDetails,
                  frecuency: donationData.frecuency,
                  startDate: donationData.startDate,
                  endDate: donationData.endDate,
                },
                createDonorCommitment,
                createDonationMatch,
              );

            addDonationMatchResponse({
              integrationType: donationData?.integrationType,
              integrationCompany: donationData?.integrationCompany,
              integrationDonationAmount,
              integrationResponse,
              donorCommitmentResponse,
            });
          }

          if (matchingCommitments && matchingCommitments.length > 0 && donationData) {
            await generatePartnershipMatchCommitment(
              {
                donationAmount: donationData.price,
                matchingCommitments,
                fundraiser,
                team: fundraiser
                  ? (fundraiser.attendee?.teamMembership?.team as TeamFundraising) || null
                  : (team as TeamFundraising),
                experienceId: experience?.id ?? donationData.experience?.id,
                campaignId: campaign?.id as string,
                donorId: user?.id as string,
                frecuency: donationData.frecuency,
                startDate: donationData.startDate,
                endDate: donationData.endDate,
              },
              createDonorCommitment,
              updatePartnershipCommitmentMutation,
            );
          }
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId: String(experienceId) }),
          );
        } else {
          notification.error(
            payment?.message || 'Failed when trying to register. Please try again.',
          );
          setLoading(false);
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_REGISTRATION, {
              experienceId: String(experienceId),
            }),
          );
        }
      } catch (error) {
        console.error('Failed to process payment:', error);
        notification.error('Failed to process payment. Please try again.');
        setLoading(false);
        navigate(
          buildUrl(LANDING_ROUTES.EXPERIENCE_REGISTRATION, { experienceId: String(experienceId) }),
        );
      }
    } else {
      console.error('experience not found');
      notification.error('Experience not found.');
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataCreditCartInit === null || executePayAfterCreditCartInit) {
      // pay proccess will excute when exist payment method, if no exist, first is createf and after this process is executed
      if (excuetePay && selectedPaymentMethod) {
        pay(selectedPaymentMethod);
        setExecutePay(false);
      }
      if (excuetePay && selectedPaymentMethod === undefined) {
        notification.error('Please select a payment method.');
        setExecutePay(false);
        setLoading(false);
      }
    } else {
      // creation proccess for credit card in stripe when is first time payment method
      const createCreditCartInit = async () => {
        try {
          const response = await dataCreditCartInit.stripe.confirmSetup({
            elements: dataCreditCartInit.elements,
            redirect: 'if_required',
          });
          if (response.error) {
            console.error(response.error.message);
            notification.error(response.error.message);
            setExecutePay(false);
            setLoading(false);
          }
          await refetch();
          setExecutePayAfterCreditCartInit(true);
        } catch (error) {
          console.error(error.message);
          notification.error(error.message);
          setExecutePay(false);
          setLoading(false);
          setExecutePayAfterCreditCartInit(true);
        }
      };
      createCreditCartInit();
    }
  }, [excuetePay, selectedPaymentMethod]);
  const onOtherMethod = () => {
    setOtherMethod(!otherMethod);
    if (paymentMethods.length > 0) {
      setExistPaymentMethod(true);
    }
  };
  return (
    <Fragment>
      {!loadingPaymentMethods && !otherMethod && paymentMethods && paymentMethods.length > 0 ? (
        <React.Fragment>
          <ContainerCards container spacing={1}>
            {paymentMethods.map((method, index) => (
              <PaymentMethodCard
                key={method?.id}
                data={method}
                active={method?.primary}
                index={index}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setCompletePayment={setCompletePayment}
              />
            ))}
          </ContainerCards>
          <Box mt="2rem" maxWidth="max-content">
            <Tooltip
              title={
                paymentMethods.length >= 3
                  ? 'You have reached the limit of 3 saved payment methods. To add a new one, please remove an existing method from your profile on the consumer site. Thank you for supporting our campaigns!'
                  : ''
              }
              placement="top"
            >
              <span>
                <Button
                  onClick={onOtherMethod}
                  disabled={paymentMethods.length >= 3}
                  style={{ display: 'block', margin: 0 }}
                >
                  Pay with other method
                </Button>
              </span>
            </Tooltip>
          </Box>
        </React.Fragment>
      ) : (
        <SavePaymentMethod
          setOtherMethod={setOtherMethod}
          refetch={refetch}
          existPaymentMethod={existPaymentMethod}
          setDataCreditCartInit={setDataCreditCartInit}
          validatePaymentData={validatePaymentData}
          setValidatePaymentData={setValidatePaymentData}
          setCompletePayment={setCompletePayment}
        />
      )}
    </Fragment>
  );
};
