var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAuth } from '@8base/react-auth';
import { useApolloClient } from '@apollo/client';
import { Amplify, Auth as AmplifyAuth } from 'aws-amplify';
import { CURRENT_USER_QUERY, USER_SIGNUP_MUTATION, useWelcomeEmailSenderMutation, } from '@vizsla/graphql';
export const AuthContext = React.createContext({});
export const AuthProvider = ({ children, authInfo }) => {
    const { authClient } = useAuth();
    const client = useApolloClient();
    const [welcomeEmailSenderMutation] = useWelcomeEmailSenderMutation();
    const [isAuthenticated, setIsAuthenticated] = React.useState(authClient.checkIsAuthorized());
    React.useEffect(() => {
        Amplify.configure({
            region: authInfo.authRegion,
            userPoolId: authInfo.authUserPoolId,
            userPoolWebClientId: authInfo.authClientId,
            oauth: {
                domain: authInfo.authDomain,
                scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                responseType: 'code',
            },
            cookieStorage: {
                domain: '.colliegives.com',
                path: '/',
                expires: 365,
                secure: true,
                sameSite: 'none',
                httpOnly: true,
            },
        });
        AmplifyAuth.currentAuthenticatedUser()
            .then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const email = user.attributes.email;
            authClient.setState({
                token,
                email,
                workspaceId: authInfo.workspaceId,
                environmentName: authInfo.workspaceEnv,
            });
            setIsAuthenticated(true);
        })
            .catch(err => {
            setIsAuthenticated(false);
        });
    }, [authInfo]);
    const loginByInvite = React.useCallback(({ email, tempPassword, newPassword }) => __awaiter(void 0, void 0, void 0, function* () {
        const cognitoUser = yield AmplifyAuth.signIn(email, tempPassword);
        const newPasswordIsRequired = cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED';
        if (newPasswordIsRequired) {
            try {
                yield AmplifyAuth.completeNewPassword(cognitoUser, newPassword);
                const userSession = cognitoUser.getSignInUserSession();
                const token = userSession.getIdToken().getJwtToken();
                const context = {
                    headers: { authorization: `Bearer ${token}` },
                };
                yield client.query({
                    query: CURRENT_USER_QUERY,
                    fetchPolicy: 'network-only',
                    context,
                });
                authClient.setState({
                    token,
                    email,
                    workspaceId: authInfo.workspaceId,
                    environmentName: authInfo.workspaceEnv,
                });
                return { email };
            }
            catch (e) {
                // todo: replace with toast message
                console.error(e);
                return { email };
            }
        }
        return { email };
    }), [client, authClient, authInfo.workspaceId, authInfo.workspaceEnv]);
    const login = React.useCallback(({ email, password }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!isAuthenticated) {
            const cognitoUser = yield AmplifyAuth.signIn(email, password);
            const userSession = cognitoUser.getSignInUserSession();
            const token = userSession.getIdToken().getJwtToken();
            const context = {
                headers: { authorization: `Bearer ${token}` },
            };
            try {
                const res = yield client.query({
                    query: CURRENT_USER_QUERY,
                    fetchPolicy: 'network-only',
                    context,
                });
                authClient.setState({
                    token,
                    email,
                    workspaceId: authInfo.workspaceId,
                    environmentName: authInfo.workspaceEnv,
                });
                return (_a = res.data) === null || _a === void 0 ? void 0 : _a.user;
            }
            catch (error) {
                try {
                    const currentUserInfo = yield AmplifyAuth.currentUserInfo();
                    const firstName = currentUserInfo.attributes['custom:name'];
                    const lastName = currentUserInfo.attributes['custom:lastName'];
                    let messaError = null;
                    try {
                        yield client.mutate({
                            mutation: USER_SIGNUP_MUTATION,
                            variables: {
                                user: { email, firstName, lastName },
                            },
                            context,
                        });
                        yield client.query({
                            query: CURRENT_USER_QUERY,
                            fetchPolicy: 'network-only',
                            context,
                        });
                        authClient.setState({
                            token,
                            email,
                            workspaceId: authInfo.workspaceId,
                            environmentName: authInfo.workspaceEnv,
                        });
                    }
                    catch (error) {
                        console.error(error);
                        messaError = error.message;
                    }
                    if (token && email && firstName && messaError === null) {
                        yield welcomeEmailSenderMutation({
                            variables: {
                                email,
                                url: window.location.origin,
                                lastName,
                                firstName,
                            },
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
            authClient.setState({
                token,
                email,
                workspaceId: authInfo.workspaceId,
                environmentName: authInfo.workspaceEnv,
            });
        }
        return undefined;
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authInfo, authClient, client, isAuthenticated]);
    const logout = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isAuthenticated) {
            yield AmplifyAuth.signOut();
            authClient.purgeState();
            setIsAuthenticated(false);
        }
    }), [authClient, isAuthenticated]);
    const register = React.useCallback(({ email, password, firstName, lastName }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isAuthenticated) {
            yield AmplifyAuth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    'custom:name': firstName,
                    'custom:lastName': lastName,
                },
            });
        }
    }), [isAuthenticated]);
    const confirmEmail = React.useCallback(({ email, code }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isAuthenticated) {
            yield AmplifyAuth.confirmSignUp(email, code);
        }
    }), [isAuthenticated]);
    const forgotPassword = React.useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        yield AmplifyAuth.forgotPassword(email);
    }), []);
    const forgotPasswordCodeVerification = React.useCallback(({ email, code, password }) => __awaiter(void 0, void 0, void 0, function* () {
        yield AmplifyAuth.forgotPasswordSubmit(email, code, password);
    }), []);
    return (_jsx(AuthContext.Provider, Object.assign({ value: {
            isAuthenticated,
            login,
            loginByInvite,
            logout,
            register,
            confirmEmail,
            forgotPassword,
            forgotPasswordCodeVerification,
        } }, { children: children })));
};
